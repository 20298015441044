<template>
  <div>
    <PageHeaderDisplay title="Sites" category="Assets" category-logo="fa-buildings">
      <template v-slot:actions
        ><router-link class="btn btn-primary mb-3" :to="{ name: 'asset-wizard' }"><i class="fa fa-plus fa-fw"></i> New Asset</router-link></template
      >
    </PageHeaderDisplay>
    <div class="content">
      <DataTable
        :key="dataTableKey"
        data-cy="assets-table"
        :items="assets"
        :total="totalAssets"
        :loading="loadingAction.list"
        :columns="columns"
        :actions="actions"
        :fetch-items="refreshTable"
        default-sort-key="siteName"
        :default-sort-direction="1"
      >
        <template v-slot:siteName="{ data: asset }">
          <router-link class="nav-link d-flex align-items-center" :to="{ name: 'asset-overview', params: { id: asset._id } }">
            <img v-if="asset.images?.length > 0" class="asset-thumb" :src="(asset.images.find(image => image.isPrimary) || asset.images[0]).url" />
            <div v-else class="asset-thumb no-image" />
            <div>
              <div class="font-size-lg">{{ asset.siteName }}</div>
              <div class="font-w400">{{ asset.addressString }}</div>
            </div>
          </router-link>
        </template>
        <template v-slot:floorArea="{ data: asset }"> {{ asset.floorArea | numberFormat }} {{ asset.floorAreaUnit | floorAreaUnit }} </template>
        <template v-slot:[`address.postCode`]="{ data: asset }">
          {{ asset.address | address }}
        </template>
      </DataTable>

      <ConfirmModal
        :open="!!modals.remove"
        title="Delete Asset"
        :text="`Please confirm you would like to remove asset:<br/><br/><strong>${modals.remove.siteName}</strong>`"
        @close="
          () => {
            modals.remove = false;
          }
        "
        @submit="onRemoveAsset"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import ConfirmModal from '@/components/ConfirmModal';
import DataTable from '@/components/DataTable';
import PageHeaderDisplay from '@/components/PageHeaderDisplay';

export default {
  name: 'AssetList',
  components: {
    ConfirmModal,
    DataTable,
    PageHeaderDisplay
  },
  data() {
    return {
      modals: {
        remove: false
      },
      dataTableKey: 0
    };
  },
  computed: {
    ...mapGetters({
      assets: 'asset/assets',
      entities: 'entity/entities',
      totalAssets: 'asset/total',
      loadingAction: 'asset/loadingAction'
    }),
    actions() {
      return [
        {
          label: 'Edit',
          icon: 'pencil',
          route: 'asset-manage',
          params: { id: '_id' }
        },
        {
          label: 'Delete',
          class: 'text-danger',
          icon: 'trash',
          onClick: this.onClickDeleteAsset
        }
      ];
    },
    columns() {
      return [
        { name: 'Site Name', code: 'siteName', search: true },
        { name: 'Floor Area', code: 'floorArea' },
        {
          name: 'Entity',
          code: 'entity.legalName',
          search: true,
          searchType: 'select2',
          searchOptions: this.entityOptions,
          searchCode: 'entityId',
          placeholder: 'All',
          noSort: true
        },
        {
          name: 'Parent Entity',
          code: 'entity.parentEntity.legalName',
          noSort: true
        }
      ];
    },
    entityOptions() {
      return this.entities
        .map(entity => ({
          value: entity._id,
          label: entity.legalName
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    }
  },
  created() {
    this.listEntities();
  },
  methods: {
    ...mapActions({
      listAssets: 'asset/list',
      removeAsset: 'asset/remove',
      listEntities: 'entity/list'
    }),
    onClickDeleteAsset(asset) {
      this.modals.remove = asset;
    },
    onRemoveAsset() {
      const { _id, siteName } = this.modals.remove;

      this.removeAsset({ id: _id });
      this.refreshTable();
      this.dataTableKey += 1;
      this.$toasted.success(`Asset "${siteName}" deleted successfully`);
      this.modals.remove = false;
    },
    async refreshTable(params) {
      await this.listAssets({
        ...params,
        data: {
          ...params.data,
          params: {
            ...params.data.params,
            $populate: 'entity,entity.parentEntity',
            $select: 'siteName,floorArea,address,images,entityId,entity.legalName,entity.parentEntityId'
          }
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.asset-thumb {
  min-width: 100px;
  height: 70px;
  object-fit: cover;
  margin-right: 10px;
  transform: translate3d(0px, 0px, 0.1px);
  margin-top: -5px;
  margin-bottom: -5px;

  &.no-image {
    background-color: #b9b9b9;
  }
}
</style>
