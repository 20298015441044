var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeaderDisplay', {
    attrs: {
      "title": "Sites",
      "category": "Assets",
      "category-logo": "fa-buildings"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('router-link', {
          staticClass: "btn btn-primary mb-3",
          attrs: {
            "to": {
              name: 'asset-wizard'
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus fa-fw"
        }), _vm._v(" New Asset")])];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "content"
  }, [_c('DataTable', {
    key: _vm.dataTableKey,
    attrs: {
      "data-cy": "assets-table",
      "items": _vm.assets,
      "total": _vm.totalAssets,
      "loading": _vm.loadingAction.list,
      "columns": _vm.columns,
      "actions": _vm.actions,
      "fetch-items": _vm.refreshTable,
      "default-sort-key": "siteName",
      "default-sort-direction": 1
    },
    scopedSlots: _vm._u([{
      key: "siteName",
      fn: function fn(_ref) {
        var _asset$images;

        var asset = _ref.data;
        return [_c('router-link', {
          staticClass: "nav-link d-flex align-items-center",
          attrs: {
            "to": {
              name: 'asset-overview',
              params: {
                id: asset._id
              }
            }
          }
        }, [((_asset$images = asset.images) === null || _asset$images === void 0 ? void 0 : _asset$images.length) > 0 ? _c('img', {
          staticClass: "asset-thumb",
          attrs: {
            "src": (asset.images.find(function (image) {
              return image.isPrimary;
            }) || asset.images[0]).url
          }
        }) : _c('div', {
          staticClass: "asset-thumb no-image"
        }), _c('div', [_c('div', {
          staticClass: "font-size-lg"
        }, [_vm._v(_vm._s(asset.siteName))]), _c('div', {
          staticClass: "font-w400"
        }, [_vm._v(_vm._s(asset.addressString))])])])];
      }
    }, {
      key: "floorArea",
      fn: function fn(_ref2) {
        var asset = _ref2.data;
        return [_vm._v(" " + _vm._s(_vm._f("numberFormat")(asset.floorArea)) + " " + _vm._s(_vm._f("floorAreaUnit")(asset.floorAreaUnit)) + " ")];
      }
    }, {
      key: "address.postCode",
      fn: function fn(_ref3) {
        var asset = _ref3.data;
        return [_vm._v(" " + _vm._s(_vm._f("address")(asset.address)) + " ")];
      }
    }], null, true)
  }), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.remove,
      "title": "Delete Asset",
      "text": "Please confirm you would like to remove asset:<br/><br/><strong>".concat(_vm.modals.remove.siteName, "</strong>")
    },
    on: {
      "close": function close() {
        _vm.modals.remove = false;
      },
      "submit": _vm.onRemoveAsset
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }